import React, { useEffect } from "react";
import NavBar from "../components/eFirma/NavBar";
import { useTranslation } from "react-i18next";
import "../scss/eFirma/eFirma.scss";
import FirstSection from "../components/eFirma/FirstSection";
import SecondSection from "../components/eFirma/SecondSection";
import ThirdSection from "../components/eFirma/ThirdSection";
import FourthSection from "../components/eFirma/fourthSection";
import FifthSection from "../components/eFirma/FifthSection";
import SixthSection from "../components/eFirma/SixthSection";
import SeventhSection from "../components/eFirma/SeventhSection";
import EighthSection from "../components/eFirma/EighthSection";
import NinthSection from "../components/eFirma/NinthSection";
import TenthSection from "../components/eFirma/tenthSection";
import Questions from "../components/eFirma/Questions";
import Footer from "../components/newIndexFooter";
import SubFooter from "../components/SubFooter";
import CookiesConsent from "../components/CookiesConsent";
import { useLocalization } from "gatsby-theme-i18n";
import { Helmet } from "react-helmet";

import useScript from "../hooks/useScript";

function EFirma() {
  const [pageY, setPageY] = React.useState(0);
  const [mobileNav, setMobileNav] = React.useState(false);
  const { defaultLang, locale, localizedPath } = useLocalization();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const handleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    const options = { passive: true }; // options must match add/remove event
    const scroll = (event) => {
      const { pageYOffset, scrollY } = window;
      setPageY(scrollY);
    };
    document.addEventListener("scroll", scroll, options);
    // remove event on unmount to prevent a memory leak
    () => document.removeEventListener("scroll", scroll, options);
  }, []);
  const [hasAccepted, setHasAccepted] = React.useState(false);
  useScript({
    isDisabled: !hasAccepted,
    src: "https://leadbooster-chat.pipedrive.com/assets/loader.js",
  });
  useEffect(() => {
    const cookies = Boolean(localStorage.getItem("hasAccepted")) || false;
    if (cookies) {
      setHasAccepted(cookies);
    }
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Conoce todo sobre la Firma electrónica; usos, beneficios y cómo utilizarla para firmar contratos y todo tipo de documentos digitales en México"
        />
        <title>Firma electrónica, ¿Qué es? y ¿Para qué sirve?</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    
      <NavBar
        className={""}
        isOpen={mobileNav}
        toggle={() => setMobileNav(!mobileNav)}
        handleClose={() => setMobileNav(false)}
        withoutButtons
      />
      <FirstSection handleDemo={handleModal} withoutStarterButton demoIsALink />
      <SecondSection />
      <ThirdSection />
      <FourthSection withoutStarterButton/>
      <FifthSection />
      <SixthSection handleDemo={handleModal} demoIsALink />
      <SeventhSection withoutStarterButton/>
      <EighthSection />
      <NinthSection />
      <TenthSection />
      <Questions />
      <div className="EFirma--footer">
        <Footer locale={locale} />
      </div>
      <SubFooter />
      {!hasAccepted && (
        <CookiesConsent handleContinue={() => setHasAccepted(true)} />
      )}
    </div>
  );
}

export default EFirma;
